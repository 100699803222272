.progress-circle {
  vertical-align: middle;
  max-width: 80px;
  max-height: 80px;
  position: relative;
}

.progress-circle-center {
  @extend .progress-circle;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
}

.progress-circle-center-middle {
  @extend .progress-circle;
  left: 40%;
  -webkit-transform: translateX(-40%);
  -ms-transform: translateX(-40%);
  transform: translateX(-40%);
  -webkit-transform: translateY(50%);
  -ms-transform: translateY(50%);
  transform: translateY(50%);
}
.progress-circle-top-right {
  @extend .progress-circle;
  left: 100%;
  -webkit-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  transform: translateX(-100%);
}

.progress-circle-top-left {
  @extend .progress-circle;
  left: 0%;
  -webkit-transform: translateX(-0%);
  -ms-transform: translateX(-0%);
  transform: translateX(-0%);
}

.progress-circle-bottom-left {
  @extend .progress-circle;
  left: 0%;
  -webkit-transform: translateX(-40%);
  -ms-transform: translateX(-40%);
  transform: translateX(-40%);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}
.progress-circle-bottom-right {
  @extend .progress-circle;
  left: 85%;
  -webkit-transform: translateX(90%);
  -ms-transform: translateX(90%);
  transform: translateX(90%);
  -webkit-transform: translateY(100%);
  -ms-transform: translateY(100%);
  transform: translateY(100%);
}

.chart-text {
  fill: $primary;
  transform: translateY(0.25em);
}
.chart-number {
  font-family: DINNextLTPro;
  font-size: 0.7em;
  line-height: 1;
  text-anchor: middle;
  transform: translateY(-0.25em);
}

.chart-label {
  font-size: 0.29em;
  text-transform: uppercase;
  text-anchor: middle;
  transform: translateY(0.7em);
}

.figure-key [class*='shape-'] {
  margin-right: 8px;
}

.figure-key-list {
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.figure-key-list li {
  margin: 7px auto;
}

.shape-circle {
  display: inline-block;
  vertical-align: middle;
  width: 41px;
  height: 41px;
  border-radius: 50%;
  background-color: $primary;
  text-transform: capitalize;
}
