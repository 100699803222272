.terms-window {
  max-width: '90%';
  height: 367px;
  margin: 23px 0 30px 2px;
  padding: 11px 6px 1px 18px;
  border-radius: 6px;
  border: solid 1px $gray_200;
  background-color: $white;
  overflow-y: scroll;
}

.terms-list {
  padding-left: 1rem;
}

.terms-list li {
  padding-top: 1rem;
}

.cash-balance-terms {
  p {
    text-align: left;
  }

  table {
    table-layout: fixed;
    width: 100%;
    border-collapse: collapse;
    border: 1px solid;
    text-align: left;
  }

  th,
  tr,
  td {
    padding: 10px;
    border: 1px solid;
  }

  ul li {
    list-style-type: disc;
    text-align: left;
    padding-top: 0px;
    padding-bottom: 0px;
  }
}
