.fluz-label {
  height: 18px;
  border-radius: 4px;
  display: inline-block;
  padding: 0px 4px;
  -webkit-font-smoothing: antialiased;
  color: $white;
  line-height: 20px;
}

.label-error {
  background-color: $danger;
}

.label {
  font-family: DINNextLTPro;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.4px;
  text-align: center;
  color: $white;
  width: 76px;
  height: 20px;
  border-radius: 3px;
  line-height: normal;
}

.label-primary {
  @extend .fluz-label;
  @extend .fluz-gradient-primary;
}

.label-gray {
  background-color: $gray_1000;
}

.label-warning {
  @extend .fluz-gradient-warning;
}

.label-info {
  @extend .fluz-label;
  @extend .fluz-gradient-blue;
}

.label-danger {
  @extend .fluz-gradient-danger;
}

.required-wrap {
  right: 0;
  width: 150px;
  min-width: 150px;
  max-width: 150px;
  position: absolute;
}

.require-relink {
  color: $warning;
  font-size: 12px;
}
.require-relink.big {
  font-size: 16px;
  font-weight: 500;
}
