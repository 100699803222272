.card {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.09);
}

.card-title {
  @extend h2;
  color: $white;
  text-align: center;
}

.card-title-left {
  @extend h2;
}

.card-body {
  overflow-wrap: normal;
}

.card-header {
  border-bottom: solid 1px $gray_200;
}

.card-body-header {
  @extend .p-regular;
  color: $gray_900;
  text-align: center;
}

.card-body-header-null {
  @extend .p-regular;
  text-align: center;
  color: $gray_500;
}

.card-body-text {
  @extend .p-small;
  margin-top: $spacing-xxs;
  text-align: center;
}

.card-footer {
  border-top: solid 1px $gray_200;
}

.vertical-divider {
  width: 1px;
  height: 33px;
  border: solid 1px $gray_100;
  align-self: center;
}

.horizontal-divider {
  height: 1px;
  border: solid 1px $gray_700;
  align-self: center;
}

@media only screen and (max-width: 991px) {
  .vertical-divider {
    display: none;
  }
}
.card-border-warning {
  border: 2px solid $warning !important;
}

.feature-banner-card {
  @extend .card;
  background-color: $white;
  max-width: calc(min(100vw - 30px, 517px));
  min-width: calc(min(100vw - 30px, 517px));
  position: relative;
  overflow: hidden;
  height: 184px;
}

.feature-banner-text-content {
  max-width: 66%;
  flex: 1;
  padding: 1.5rem 1.5rem 1.5rem 2rem;
}

.feature-banner-image {
  width: 40%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
