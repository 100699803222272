$layout-breakpoint-small: 628px;

.slider-arrow-container {
  top: 0;
  position: absolute;
  height: 100%;
  width: 100px;
  background: linear-gradient(90deg, #ebedef 19.79%, rgba(235, 237, 239, 0) 88.37%);
  display: flex;
  align-items: center;

  &-left {
    left: 0;

    @media screen and (max-width: 710px) {
      background: none;
    }
  }

  &-right {
    right: 0;
    justify-content: flex-end;
    background: linear-gradient(270deg, #ebedef 19.79%, rgba(235, 237, 239, 0) 88.37%);

    @media screen and (max-width: 710px) {
      background: none;
    }
  }

  @media screen and (max-width: $layout-breakpoint-small) {
    width: 24px;
  }
}

.slider-arrow-btn {
  width: 44px;
  height: 44px;
  border-radius: 22px;
  border: none;
  background: #3c4b64;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px -2px rgba(16, 24, 40, 0.06), 0px 4px 8px -2px rgba(16, 24, 40, 0.1);

  @media screen and (max-width: $layout-breakpoint-small) {
    width: 24px;
    height: 24px;
    border-radius: 18px;
  }
}
