// If you want to override variables do it here
@import './variables/variables';
@import './variables/colors';
@import './variables/fonts';
@import './variables/toaster';
@import './variables/spacing';
// Import CoreUI styles
@import '~@coreui/coreui/scss/coreui.scss';
@import '~bootstrap/scss/bootstrap';

//mixins
@import './mixins/background-variant';
@import './mixins/hover-focus';

// If you want to add something do it here
@import './styles/gradients.style';
@import './styles/typo.style';
@import './styles/layouts.style';
@import './styles/card.style';
@import './styles/list.style';
@import './styles/buttons.style';
@import './styles/badges.style';
@import './styles/forms.style';
@import './styles/toaster.style';
@import './styles/label.style';
@import './styles/table.style';
@import './styles/avatar.style';
@import './styles/pin.style';
@import './styles/progress-bar.style';
@import './styles/tooltip.style';
@import './styles/color.style';
@import './styles/terms.style';
@import './styles/slider.style';
@import './styles/account-switcher.style';

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
