h1,
h2,
h6,
p {
  margin-bottom: 0;
}

h1 {
  font-family: DINNextLTPro;
  font-size: 36px;
  letter-spacing: -0.5px;
  color: $gray_1000;
}

h2 {
  font-family: DINNextLTPro;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.31px;
  color: $gray_1000;
}

h6 {
  font-family: DINNextLTPro;
  font-size: 14px;
  letter-spacing: -0.19px;
  font-weight: 900;
  color: $gray_800;
}

p {
  font-family: DINNextLTPro;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-weight: normal;
  letter-spacing: -0.24px;
  color: $gray_700;
}

.p-lg {
  font-size: 18px;
  line-height: 1.33;
  letter-spacing: -0.306px;
  @extend p;

  &-gray-1000 {
    color: $gray_1000;
  }
}

.p-mid {
  font-size: 15px;
  letter-spacing: -0.21px;
  font-family: DINNextLTPro;
  font-style: normal;
  line-height: normal;
  font-stretch: normal;
  font-weight: normal;
}

.p-regular {
  font-size: 17px;
  line-height: 1.29;
  @extend p;

  &-gray-1000 {
    color: $gray_1000;
  }
}

.p-regular-dark {
  @extend .p-regular;
  color: $gray_900 !important;
}

.p-regular-primary {
  @extend .p-regular;
  color: $primary !important;
}

.p-small {
  font-size: 14px;
  line-height: 1.43;
  letter-spacing: -0.29px;
  @extend p;

  &-gray-1000 {
    color: $gray_1000;
  }

  &-gray-500 {
    color: $gray_500;
  }
}

.p-extra-small {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: -0.25px;
  @extend p;
}

.p-small-dark {
  @extend .p-small;
  color: $gray_900;
}

.one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.line-through {
  text-decoration: line-through;
}

.label-content {
  @extend p;
  font-size: 13px;
  font-weight: 500;
}

.bold {
  font-weight: bold;
}

$layout-breakpoint-small: 650px;

@media (min-width: $layout-breakpoint-small) {
  .modal-md-lg {
    margin-top: 50%;
    position: relative;
    left: -60.5px;
    width: 621px;
  }
}

.p-input-label-description {
  @extend p;
  color: $gray_800;
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: 12px;
  line-height: 13px;
}
