.list-title {
  @extend .p-regular;
  color: $gray_900;
}

.list-text {
  @extend .p-small;
}

.list-no-entry {
  @extend .p-regular;
  text-align: center;
}

.list-item-row:nth-child(even) {
  background-color: rgba(235, 237, 239, 0.5);
}

.list-item-row {
  height: 68px;
}
.list-item-row::-webkit-scrollbar {
  display: none;
}

.list-item-title {
  @extend .p-regular;
  color: $gray_900;
}

.list-icon-medium {
  @extend .p-regular;
  width: 42px;
  height: 30px;
  border-radius: 4px;
}

.list-icon-small {
  @extend .p-regular;
  width: 37px;
  height: 26px;
  border-radius: 3px;
}

.list-icon-x-small {
  @extend .p-extra-small;
  width: 22px;
  height: 17px;
  border-radius: 0.5px;
}

.list-block {
  border-left: solid 1px $gray_200;
  border-right: solid 1px $gray_200;
  min-height: 70.5px;
}

.list-parent {
  border-top: solid 1px $gray_200;
  border-bottom: solid 1px $gray_200;
  border-radius: 4px;
}

.list-parent > div:not(:first-child) {
  border-top: solid 1px $gray_200;
}

.pre-wrap-text {
  white-space: pre-wrap;
}