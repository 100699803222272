@media screen and (max-width: 900px) {
  .toast {
    max-width: 250px !important;
    width: 250px !important;
  }
}

@media screen and (min-width: 900px) {
  .toast {
    max-width: 650px !important;
    width: 650px !important;
  }
}

.toast-header {
  font-size: $toast-font-size;
  letter-spacing: $toast-header-letter-spacing;
  justify-content: space-between;
  .close {
    width: 10px;
    height: 10px;
    border-radius: 0.5px;
    background-color: transparent;
    color: $white;
    padding-bottom: 5px;
    font-weight: 100;
    line-height: 0.5;
    margin-bottom: auto;
    &:hover {
      color: $white;
    }
    &:focus {
      outline: 0;
    }
  }
}
.toast-header-danger {
  @extend .toast-header;
  font-family: DINNextLTPro;
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.4px;
  color: $danger;
}

.toast-header-success {
  @extend .toast-header;
  color: $success;
}
.toast-header-warning {
  @extend .toast-header;
  color: $warning;
}
.toast-header-info {
  @extend .toast-header;
  color: $info;
}

.toast-body {
  font-family: DINNextLTPro;
  font-size: 17px;
  line-height: 1.29;
  letter-spacing: -0.24px;
  color: $white;
}


.toast-sm {
  max-width: 250px !important;
  width: 250px !important;
}

.toast-header-sm {
  @extend .toast-header;
  padding-top: 13px;
  padding-left: 17px;
  padding-right: 13px;
  padding-bottom: 8px;
  font-weight: 500;
}

.toast-body-sm {
  @extend .toast-body;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 17px;
}
