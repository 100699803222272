.btn {
  min-width: 95px;
  min-height: 35px;
  border-radius: 3px;
  font-family: DINNextLTPro;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  font-size: 17px;
  letter-spacing: -0.25px;
  text-align: center;
  &:hover {
    opacity: 0.65;
  }
  &:active {
    opacity: 0.85;
  }
}

.btn-hover-active-gray {
  &:hover {
    color: $gray_800;
  }
  &:active {
    color: $gray_800;
  }
}

.btn-hover-active-white {
  &:hover {
    color: $white;
  }
  &:active {
    color: $white;
  }
}

.btn-submit {
  @extend .btn;
  color: $white;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.13);
  border-color: #00000000;
  border-image: #00000000;
  border: 0px;
  background-color: $gradient_primary_start;
  @extend .btn-hover-active-white;
}

.btn-warning {
  @extend .btn;
  @extend .fluz-gradient-warning;
  color: $white;
  @extend .btn-hover-active-white;
}

.btn-danger {
  @extend .btn;
  background-color: $danger;
  color: $white;
  @extend .btn-hover-active-white;
}

.btn-social-auth {
  color: $gray_800;
  border: solid 1px $gray_300;
  @extend .btn;
  @extend .btn-hover-active-gray;
}

.btn-link {
  @extend .btn;
  min-width: 0;
  min-height: 0;
  font-size: 17px;
  border-radius: 0px;
  width: fit-content;
  outline: none;
  border: none;
  @extend .btn-hover-active-gray;
}

.btn-transparent {
  @extend .btn;
  color: $gray_700;
  background-color: $halfgray_100;
  &:focus {
    outline: 0;
  }
  @extend .btn-hover-active-gray;
}

.btn-apply {
  @extend .btn;
  color: $white;
  border: solid 1px $white;
  @extend .btn-hover-active-white;
}

.btn-clear {
  @extend .p-regular;
  color: $primary;
  text-align: center;
  @extend .btn-hover-active-gray;
}

.btn:disabled {
  pointer-events: none;
}

.btn-gray {
  @extend .btn-submit;
  background-color: $gray_900;
  width: fit-content;
  &:hover {
    background-color: $gray_700;
  }
  @extend .btn-hover-active-gray;
}

.btn-light-gray {
  background-color: $gray_100;
  color: $gray_800;
  @extend .btn-hover-active-gray;
}

.btn-bright-lime-green {
  color: $white !important;
  background: $success !important;
  @extend .btn-hover-active-gray;
}

.dropdown-btn-gray {
  border-color: $gray_1000 !important;
  background: $gray_1000 !important;
  background-color: $gray_1000 !important;
}

button.close {
  &:focus {
    outline: 0;
  }
}

.switch-container {
  display: flex;
  margin-bottom: 20px;
}

.switch-option {
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 17px 18.7px;
  text-align: start;

  .header {
    color: $gray_1000;
    font-weight: 750;
    margin-bottom: 5px;
  }

  .subtitle {
    color: $gray_800;
  }

  &:hover {
    background-color: $gray_100;
    opacity: 1;
  }

  &.selected {
    background-color: $gray_50;
    border: 1px solid $gray_800;

    .subtitle {
      color: $gray_1000;
    }

    &:hover {
      background-color: $gray_100;
    }
  }
}

.circle-mark {
  width: 20px;
  height: 20px;
  border: 2px solid $gray_500;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-gray-1000 {
  @extend .btn-submit;
  background-color: $gray_1000;
  width: fit-content;
  @extend .btn-hover-active-gray;
}

.btn-icon {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-default {
  cursor: default;
}

.btn-icon-rounded {
  width: 34px;
  height: 34px;
  background-color: $gray_100;
  border-radius: 6px;
  border: 1.5px solid var(--Neutrals-gray_100, #ebedef);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 0.65;
  }
}

.btn-icon-rounded-with-text {
  @extend .btn-icon-rounded;
  width: fit-content;
  padding: 8px 18px 8px 18px;
  color: #3c4b64;
  font-size: 17px;
  line-height: 17px;
  letter-spacing: -0.24px;
}
