.form-control {
  height: 35px;
  border-radius: 3px;
  border: solid 1px $gray_100;
  background-color: $halfgray_100;
  font-family: DINNextLTPro;
  font-size: 17px;
  letter-spacing: -0.24px;
  color: $gray_1000;
  &:disabled {
    background-color: $halfgray_100;
  }
  &:read-only {
    background-color: $halfgray_100;
  }
}

/** google place auto-complete */
.pac-container {
  z-index: 2147483647;
}

.pac-logo:after {
  display: none;
}

.pac-item-query {
  @extend .p-regular;
  line-height: 42px;
  padding-left: 0.5rem;
}

.pac-icon {
  display: none;
}

.DateRangePicker_picker {
  z-index: 1100 !important;
}

.DateInput_input {
  text-align: center;
  @extend .p-regular;
}

.CalendarDay__selected_span {
  background-color: $gray_900 !important; //background
  color: white !important; //text
  border-color: white !important; //default styles include a border
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $gray_900 !important;
  color: white !important;
  border-color: white !important;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background-color: $gray_600 !important;
  color: white !important;
  border-color: white !important;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $gray_600 !important;
  color: white !important;
  border-color: white !important;
}

.input-label {
  font-family: DINNextLTPro;
  font-size: 17px;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  color: $gray_900;

  &-description {
    text-align: right;
  }
}

.custom-control-label.gray-checkbox::before {
  width: 18px;
  height: 18px;
  top: 0;
  background-color: $gray_50;
  border-color: $gray_200;
}

.custom-control-label.gray-checkbox::after {
  width: 18px;
  height: 18px;
  top: 0;
}

.custom-date-input {
  .SingleDatePicker {
    width: 0 !important;
    height: 0 !important;

    .SingleDatePickerInput {
      width: 0 !important;
      height: 0 !important;

      .DateInput__small {
        width: 0 !important;
        height: 0 !important;

        input {
          width: 0 !important;
          height: 0 !important;
          padding: 0 !important;
        }

        svg.DateInput_fang {
          display: none;
        }
      }

      .SingleDatePicker_picker {
        top: 5px !important;
      }
    }
  }
}

.always-show-error {
  display: block !important;
}
