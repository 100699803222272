@import "color.style";

.account-switcher-dropdown {
  background-color: $gray_50;
  box-shadow: 0px 0px 5px 0px #00000020;
  border-radius: 6px;
  padding: 0;

  & .dropdown-header {
    padding: 10px 0 4px 14px;
    color: $gray_500;
    @extend .p-extra-small;
  }

  & .dropdown-divider {
    border-top: 1px solid $gray_200;
    margin: 0;
  }

  & .dropdown-item {
    padding: 8px 14px 12px;
  }

  & .dropdown-item.active {
    background: $gray_100 !important;
    border-left: 3px solid #A321FF;
  }
}
