@mixin color-modifiers($attribute: 'color') {
  @each $name, $hex in $text_colors {
    &-#{$name} {
      #{$attribute}: $hex;
    }
  }
}

.color {
  @include color-modifiers();
}

.background-color {
  @include color-modifiers('background-color');
}

.filter-blue {
  filter: brightness(0) saturate(100%) invert(50%) sepia(32%) saturate(2270%) hue-rotate(176deg) brightness(103%) contrast(101%);
}

.filter-green {
  filter: brightness(0) saturate(100%) invert(74%) sepia(25%) saturate(1003%) hue-rotate(46deg) brightness(91%) contrast(93%);
}

.filter-orange {
  filter: brightness(0) saturate(100%) invert(83%) sepia(33%) saturate(4640%) hue-rotate(324deg) brightness(100%) contrast(101%);
}

.filter-purple {
  filter: brightness(0) saturate(100%) invert(48%) sepia(82%) saturate(2270%) hue-rotate(265deg) brightness(95%) contrast(102%);
}
