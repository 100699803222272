.fluz-avatar {
  background-color: $halfgray_100;
}
.avatar-text-primary {
  @extend .p-regular;
  color: $gray_900;
  text-align: end;
}
.avatar-text-sub {
  @extend .p-small;
  color: $gray_600;
  text-align: end;
}

.avatar-initial {
  align-items: center;
  background-color: $gray_50;
  border: 1px solid $gray_1000;
  font-size: 22px;
  font-weight: 750;
  display: flex;
  justify-content: center;
  height: 45px;
  width: 45px;
}
