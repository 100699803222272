.tooltip-inner {
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.17);
  background-color: $white;
  border-radius: 1.5px;
  padding: 7px;
  @extend .p-small;
}

.bs-tooltip-right .arrow::before {
  border-right-color: white;
}

.tooltip.show {
  opacity: 1;
}
