@import '../mixins/hover-focus';
@import '../mixins/background-variant';

// Fluz custom colors
$enable-gradients: true;

$primary: #b621ff;
$success: #30da42;
$warning: #ffa918;
$danger: #ff5050;

$gradient_primary_start: #b621ff;
$gradient_primary_stop: #7742ff;

$white: #ffffff;

$halfgray_100: rgba(235, 237, 239, 0.5);
$gray_50: #f5f6f7;
$gray_100: #ebedef;
$gray_200: #d9dbdf;
$gray_300: #c4c9d0;
$gray_400: #b1b7c1;
$gray_500: #9da5b1;
$gray_600: #8b93a2;
$gray_700: #768191;
$gray_800: #636f83;
$gray_900: #505d73;
$gray_1000: #3c4b64;
$light_primary: rgba(163, 33, 255, 0.1);

$text_colors: (
  primary: #b621ff,
  success: #30da42,
  warning: #ffa918,
  danger: #ff5050,
  gray_50: #f5f6f7,
  gray_100: #ebedef,
  gray_200: #d9dbdf,
  gray_300: #c4c9d0,
  gray_400: #b1b7c1,
  gray_500: #9da5b1,
  gray_600: #8b93a2,
  gray_700: #768191,
  gray_800: #636f83,
  gray_900: #505d73,
  gray_1000: #3c4b64,
  black_200: #73828d,
  white: #fff,
  light_primary: rgba(163, 33, 255, 0.1),
);

@each $color, $value in $text-colors {
  @include bg-variant('.bg-#{$color}', $value);
}

// Fluz custom gradient colors
$primary-gradient: (
  'start': #b621ff,
  'stop': #7742ff,
);

$secondary-gradient: (
  'start': #c8d2dc,
  'stop': $white,
);

$light-gradient: (
  'start': #e3e8ed,
  'stop': $white,
);

$dark-gradient: (
  'start': #3c4b64,
  'stop': #212333,
);

$danger-gradient: (
  'start': #e55353,
  'stop': #d93737,
);

$warning-gradient: (
  'start': #f9b115,
  'stop': #f6960b,
);

$success-gradient: (
  'start': #2eb85c,
  'stop': #1b9e3e,
);

$info-gradient: (
  'start': #39f,
  'stop': #2982cc,
);

$theme-gradients: ();
// stylelint-disable-next-line scss/dollar-variable-default
$theme-gradients: map-merge(
  (
    'primary': $primary-gradient,
    'secondary': $secondary-gradient,
    'success': $success-gradient,
    'info': $info-gradient,
    'warning': $warning-gradient,
    'danger': $danger-gradient,
    'light': $light-gradient,
    'dark': $dark-gradient,
  ),
  $theme-gradients
);
