.modal-input-warning {
  font-family: DINNextLTPro;
  font-size: 13px;
  letter-spacing: -0.27px;
  color: $danger;
}

.masked-input {
  font-family: input-mask;
  -webkit-text-security: disc;
  &::placeholder {
    font-family: DINNextLTPro;
  }
}
