.fluz-gradient-primary {
  background: linear-gradient(to left, $gradient_primary_stop, $gradient_primary_start);
}

.fluz-gradient-primary-top {
  background: linear-gradient(to top, $gradient_primary_stop 13%, $gradient_primary_start 75%);
}

.bg-fluz-gradient-primary {
  @extend .fluz-gradient-primary-top;
  border-color: #d421ff;
}

.fluz-gradient-gray {
  background: linear-gradient(to left, #3c4b64, #3c3c3c 100%) !important;
}

.fluz-gradient-green {
  background: linear-gradient(to left, #2bde3e, #30da8f 100%) !important;
}

.fluz-gradient-yellow {
  background: linear-gradient(to left, #ff8f35, #ffb718 100%);
}

.fluz-gradient-blue {
  background: linear-gradient(to left, #2982cc, #39f 100%);
}

.text-fluz-gradient-primary {
  @extend .fluz-gradient-primary;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.button-fluz-gradient-green {
  background: linear-gradient(to left, #2bde3e, #30da8f 100%);
}

.fluz-gradient-warning {
  background: linear-gradient(to left, map-get($warning-gradient, 'stop'), map-get($warning-gradient, 'start') 100%);
}

.fluz-gradient-danger {
  background: linear-gradient(to left, map-get($danger-gradient, 'stop'), map-get($danger-gradient, 'start') 100%);
}
